<template>
    <div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <EpisodeHeader
                :episodes="episodes"
                :episode="computedEpisode"
                :tab="'ACTION_PLAN'"
                @episode-changed="episodeChanged"
            />
        </div>

        <div class="grid grid-cols-3 gap-5 mt-5">
            <div class="col-span-1 bg-white-pure l-box-shadow rounded">
                <Calendar/>
            </div>

            <div class="col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">

                <div class="flex items-center mb-4">
                    <div class="mr-2">
                        <fa-icon icon="filter" class="text-sm text-black" />
                    </div>
                    <v-checkbox
                        hide-details
                        class="mt-0 mr-4"
                        :ripple="false" 
                        label="Assigned to me"
                        v-model="hasTreatmentEvents"
                        @change="clearFilters"
                    ></v-checkbox>

                </div>
                <AppointmentsTable 
                :table-data="appointments"
                />

            </div>
        </div>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import { mapState } from 'vuex'
import EpisodeHeader from '@/components/episodes/partials/EpisodeHeader'
import AppointmentsTable from '@/components/appointments/partials/AppointmentsTable'
import ActionPlanTable from '@/components/action-plan/partials/ActionPlanTable'
import Calendar from '@/components/appointments/partials/Calendar'
import GET_ACTION_PLANS_FOR_EPISODE from '@/graphql/queries/getActionPlansForEpisode.gql'
import GET_USERS from '@/graphql/queries/getUsers.gql'

export default {
    name: 'Appointments',
    components: { ActionPlanTable, EpisodeHeader, Calendar, AppointmentsTable },
    apollo: {
        episodes: {
            query: GET_EPISODES_FOR_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({ episodes }) => {
                episodes.forEach(episode => {
                    episode.siteSection = `${episode.site}-${episode.section}`
                })
                const openEpisodes = episodes.filter(e => e && e.status === 'OPEN')
                const otherEpisodes = episodes.filter(e => e && e.status !== 'OPEN')
                return camelcaseKeys([...openEpisodes, ...otherEpisodes], {deep: true})
            },
        },
        actionPlans: {
            query: GET_ACTION_PLANS_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.currentEpisode.episodeId),
                    relevant: !this.showCurrent ? null : this.showCurrent
                }
            },
            skip() {
                return this.currentEpisode == null || this.currentEpisode.episodeId == null
            },
            result({ data: { actionPlans } }) {
                let convertedActionPlans = camelcaseKeys(actionPlans, {deep: true})
                this.actionPlans = [...convertedActionPlans]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, {deep: true})
            },
        }
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
            episodes: [],
            actionPlans: null,
            users: [],
            hasTreatmentEvents: false,
            defaultActionPlan: {
                order: null,
                goal: null,
                action: null,
                commenced: null,
                completed: null,
                rating: null,
                timeFrame: null
            },
            episode: {},
            showCurrent: true,
            appointments: [{
                date: '11/08/2020',
                time: '1:00PM',
                type: 'Internal',
                client: {
                    id: 0,
                    firstName: 'Dominique',
                    lastName: 'Elephant'
                },
                location: 'Street uni',
                worker: 'Meena Ng',
                descript: 'Description',
            }]
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
        this.$root.$on('create-action-plan', this.createActionPlan)
    },
    methods: {
        episodeChanged(episode) {
            this.$store.commit('SET_CURRENT_EPISODE', episode)
            this.episode = {...episode}
        },
        createActionPlan(action_plan) {
            console.log('create action plan',action_plan)
            const actionPlan = {...this.defaultActionPlan}
            this.actionPlans.push({
                ...actionPlan
            })
        },
        clearFilters() {
            // if none of the filters has a value, set to null since that's the only way to get every case note
            if (!this.showCurrent) this.showCurrent = null
        },
        tableDataUpdated(actionPlans) {
            this.actionPlans = actionPlans
        },
        actionPlansUpdated() {
            this.$apollo.queries.actionPlans.refetch()
        }
    },
    computed: {
        ...mapState({
            currentEpisode: state => state.app.currentEpisode
        }),
        computedEpisode() {
            return this.currentEpisode || this.episode
        },
    },
}
</script>
