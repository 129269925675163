<template>
    <div class="c-action-plan__table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            hide-default-footer
            item-key="type"
            :headers="headers"
            :items="tableData"
            class="a-table w-full"
            data-cy="recordList"
            @click:row="rowClickHandler"
        >
            <template class="" v-slot:item.editRecord="{ item }">
                <div class="text-right" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        @click.prevent="rowClickHandler(item)"
                        class="hover:text-primary mx-2"
                    >

                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import TypeIcon from '@/components/partials/TypeIcon'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'

export default {
    name: 'AppointmentsTable',
    components: { TypeIcon },
    mixins: [ ClientHelpers ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        episode: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
            headers: [
                { text: 'Date', align: 'left', value: 'date', class: 'name--header', sortable: false },
                { text: 'Time', align: 'left', value: 'time', class: 'name--header', sortable: false },
                { text: 'Type', align: 'left', value: 'type', class: 'count--header', sortable: false },
                { text: 'Location', align: 'left', value: 'location', class: 'count--header', sortable: false },
                { text: 'Worker', align: 'left', value: 'worker', class: 'count--header', sortable: false },
                { text: 'Description', align: 'left', value: 'descript', class: 'count--header', sortable: false },
                { text: '', align: 'left', value: 'editRecord', fixed: true, sortable: false},
            ]
        }
    },
    methods: {
        rowClickHandler(item) {
            console.log('item:', item)
            // this.$store.commit('SET_CURRENT_EPISODE', item.episode)
            this.$router.push({
                name: 'record-view',
                params: {clientId: this.clientId, type: item.type},
            }).catch(err => err)
        },
    },
}
</script>
